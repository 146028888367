'use client';

import Link from 'next/link';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ReCAPTCHA from 'react-google-recaptcha';
import CircularProgress from '@mui/material/CircularProgress';
import type { ContactFormData, ContactFormProps } from './types';

const textFieldProps = {
  input: { className: 'text-[0.875rem] leading-5' },
};

declare global {
  interface Window {
    recaptchaOptions?: { [key: string]: unknown };
  }
}

export function ContactForm({
  translations,
  onSubmit,
  recaptchaSiteKey,
  tosLink = '/politica-de-privacidad',
  className,
  successMessage,
  additionalFields,
}: ContactFormProps): JSX.Element {
  const [showAlert, setShowAlert] = useState({ show: false, type: 'success' as 'success' | 'error' });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { register, handleSubmit, formState, reset } = useForm<ContactFormData>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      tos: false,
    },
  });
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    window.recaptchaOptions = {
      enterprise: true,
    };
  }, []);

  const handleFormSubmit = async (data: ContactFormData): Promise<void> => {
    try {
      const recaptchaCode = await recaptchaRef.current?.executeAsync();
      const { type } = await onSubmit({
        data,
        recaptchaCode: recaptchaCode ?? undefined,
      });

      setShowAlert({ show: true, type });

      if (type === 'success') {
        reset();
      }
    } catch (error) {
      setShowAlert({ show: true, type: 'error' });
    }
  };

  return (
    <div className={className}>
      {showAlert.show && showAlert.type === 'success' ? (
        successMessage || (
          <div className="flex flex-col items-center justify-center w-full sm:py-4 bg-accent-1 rounded-lg py-8 px-4 text-center">
            <CheckIcon className="w-8 h-8 text-[#6CC24A]" />
            <p className="text-lg font-medium mt-2">{translations.success}</p>
          </div>
        )
      ) : (
        <div className="flex flex-col">
          {translations.title ? (
            <h5 className="text-base md:text-xl text-primary leading-6 md:leading-7">{translations.title}</h5>
          ) : null}
          {translations.subtitle ? <p className="mt-2 text-sm leading-5">{translations.subtitle}</p> : null}

          <form
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- don't know why get this error in handleSubmit
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />
            <div className="flex flex-col mt-4">
              <TextField
                className="mb-2"
                id="filled-basic"
                label={translations.name}
                variant="filled"
                {...register('name', { required: translations.isRequired })}
                InputProps={textFieldProps.input}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
              <TextField
                className="mb-2"
                id="filled-basic"
                label={translations.email}
                variant="filled"
                {...register('email', { required: translations.isRequired })}
                InputProps={textFieldProps.input}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
              <TextField
                className="mb-2"
                id="filled-basic"
                label={translations.phone}
                variant="filled"
                {...register('phone', { required: translations.isRequired })}
                InputProps={textFieldProps.input}
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message}
              />
              <TextField
                className="mb-4 lg:mb-2"
                id="filled-basic"
                label={translations.message}
                minRows={4}
                multiline
                variant="filled"
                {...register('message', { required: translations.isRequired })}
                InputProps={textFieldProps.input}
                error={Boolean(errors.message)}
                helperText={errors.message?.message}
              />

              <FormControl component="fieldset" error={Boolean(errors.tos)}>
                <FormGroup className="mb-4 lg:mb-0">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={errors.tos?.message ? 'error' : 'primary'}
                        inputProps={{ className: 'border border-[#ADADA9]' }}
                        sx={{
                          color: errors.tos?.message ? 'var(--mui-palette-error-main)' : '',
                        }}
                      />
                    }
                    label={
                      <span>
                        {translations.tosMsg1}&nbsp;
                        <Link href={tosLink} target="_blank">
                          {translations.tosMsg2}
                        </Link>
                        &nbsp;
                        {translations.tosMsg3}
                      </span>
                    }
                    sx={{
                      color: errors.tos?.message ? 'var(--mui-palette-error-main)' : '',
                    }}
                    {...register('tos', { required: translations.isRequired })}
                    classes={{ label: 'text-[0.75rem] leading-[1.125rem]' }}
                  />
                </FormGroup>
                <FormHelperText>{errors.tos?.message}</FormHelperText>
              </FormControl>

              <div className="mb-4 lg:mt-1">
                <Button
                  className="w-full md:w-[10.625rem] text-lg md:text-sm leading-6 md:leading-5 font-medium md:py-2.5"
                  color="primary"
                  disableElevation
                  disabled={isSubmitting}
                  startIcon={isSubmitting ? <CircularProgress size={16} /> : undefined}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? translations.loading : translations.send}
                </Button>
              </div>

              {showAlert.show && showAlert.type === 'error' ? (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity={showAlert.type as AlertColor}>
                  {translations.error}
                </Alert>
              ) : null}

              {translations.legalNotice ? (
                <div className="mt-2 text-xxs text-black-60 leading-4">{translations.legalNotice}</div>
              ) : null}
            </div>
          </form>
        </div>
      )}
      {additionalFields}
    </div>
  );
}
